import { Organization, Organizations } from '@/types/organization.type';
import { create } from 'zustand';

interface OrganizationsStore {
  organizations: Organization[];
  isLoading: boolean;
  // isClaimFormFilled: boolean;
  // setIsClaimFormFilled: (isFilled: boolean) => void;
  selectedOrganization: Organization | undefined;
  organizationPermissions: { [key: string]: boolean };
  organizationUsage: { [key: string]: number };
  organizationGetStartedProgress: { [key: string]: number };
  setOrganization: (organizations: Organizations, orgId?: string) => void;
  setSelectedOrganization: (id: string) => void;
  updateOrganization: (organization: Organization, newJobRole?: string) => void;
  setOrganizationPermissions: () => void;
  setOrganizationUsage: (usage: { [key: string]: number }) => void;
  setOrganizationGetStartedProgress: (progress: {
    [key: string]: number;
  }) => void;
}

export const useOrganizationStore = create<OrganizationsStore>((set, get) => ({
  organizations: [],
  isLoading: true,
  // isClaimFormFilled: false,
  selectedOrganization: undefined,
  organizationPermissions: {},
  organizationUsage: {},
  organizationGetStartedProgress: {},
  // setIsClaimFormFilled: (isFilled: boolean) => {
  //   set(state => ({
  //     isClaimFormFilled: isFilled
  //   }));
  // },
  setOrganization: (_organizations: Organizations, orgId?: string) => {
    if (_organizations?.organization?.length > 0) {
      set(state => ({
        organizations: _organizations?.organization
      }));
    }

    let defaultOrganization: Organization | undefined;
    // Select organization by orgId
    if (orgId) {
      // Find default
      defaultOrganization = _organizations?.organization.find(
        organization => organization?.id === orgId
      );
    }

    // Find default
    if (!defaultOrganization) {
      defaultOrganization = _organizations?.organization.find(
        organization => organization?.org_users?.[0]?.is_default === true
      );

      // Select the first one
      if (!defaultOrganization && _organizations?.organization?.length > 0) {
        defaultOrganization = _organizations?.organization[0];
      }
    }

    // Set default organization
    if (defaultOrganization) {
      set(state => ({
        selectedOrganization: defaultOrganization
      }));
    }

    // Set organization permissions
    get().setOrganizationPermissions();

    // Stop loading
    set(state => ({
      isLoading: false
    }));
  },
  setSelectedOrganization: (id: string) => {
    // Find default
    const selectedOrganization = get().organizations.find(
      organization => organization?.id === id
    );

    if (selectedOrganization) {
      set(state => ({
        selectedOrganization: selectedOrganization
      }));
    }

    // Set organization permissions
    get().setOrganizationPermissions();

    // Reset the organization usage
    set(state => ({
      organizationUsage: {}
    }));

    set(state => ({
      organizationGetStartedProgress: {}
    }));
  },
  updateOrganization: (organization: Organization, newJobRole?: string) => {
    // Update the selected organization name
    set(state => ({
      selectedOrganization: {
        ...(state.selectedOrganization as Organization),
        name: organization?.name,
        website: organization?.website,
        size: organization?.size,
        industry: organization?.industry,
        org_users: newJobRole
          ? [{ ...organization?.org_users?.[0], user_job_role: newJobRole }]
          : [{ ...organization?.org_users?.[0] }]
      }
    }));

    // Update the name in the list
    set(state => ({
      organizations: state.organizations.map((_organization: Organization) => {
        return _organization &&
          _organization?.id === state?.selectedOrganization?.id
          ? {
              ..._organization,
              organization: {
                ..._organization,
                name: state.selectedOrganization?.name,
                website: state.selectedOrganization?.website,
                size: state.selectedOrganization?.size,
                industry: state.selectedOrganization?.industry,
                org_users: newJobRole
                  ? [
                      {
                        ...state.selectedOrganization?.org_users?.[0],
                        user_job_role: newJobRole
                      }
                    ]
                  : [
                      {
                        ...state.selectedOrganization?.org_users?.[0]
                      }
                    ]
              }
            }
          : _organization;
      })
    }));
  },
  setOrganizationPermissions: () => {
    const selectedOrganization = get().selectedOrganization;

    // Set organization permissions
    if (
      selectedOrganization?.org_users?.[0]?.custom_role
        ?.custom_role_permissions &&
      selectedOrganization?.org_users?.[0]?.custom_role?.custom_role_permissions
        ?.length > 0
    ) {
      const customRolePermissions =
        selectedOrganization?.org_users?.[0]?.custom_role
          ?.custom_role_permissions;
      const customRolePermissionsObject: { [key: string]: boolean } = {};

      customRolePermissions.forEach(permission => {
        if (permission?.custom_permission_id) {
          customRolePermissionsObject[permission?.custom_permission_id] = true;
        }
      });

      set(state => ({
        organizationPermissions: customRolePermissionsObject
      }));
    } else {
      set(state => ({
        organizationPermissions: {}
      }));
    }
  },
  setOrganizationUsage: (usage: { [key: string]: number }) => {
    set(state => ({
      organizationUsage: usage
    }));
  },

  setOrganizationGetStartedProgress: (progress: { [key: string]: number }) => {
    set(state => ({
      organizationGetStartedProgress: progress
    }));
  }
}));
