import {
  GET_ASSIGN_TO_OTHERS_RUNS_COUNT_QUERY,
  GET_ORG_USERS_COUNT_QUERY
} from '@/graphql/get-started.gql';
import { GET_ORGANIZATION_USAGE_QUERY } from '@/graphql/organization.gql';
import { useOrganizationStore } from '@/stores/organization.store';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { useLazyQuery } from '@apollo/client';
import { useUserId } from '@nhost/nextjs';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

export function FetchGetStartedProgressComponent() {
  // Variables
  const userId = useUserId();

  // Stores
  const [selectedOrganization, setOrganizationGetStartedProgress] =
    useOrganizationStore(
      s => [s.selectedOrganization, s.setOrganizationGetStartedProgress],
      shallow
    );
  const [runChecklistTree] = useRunChecklistStore(
    s => [s.runChecklistTree],
    shallow
  );

  // GraphQL
  const [getOrganizationUsage] = useLazyQuery(GET_ORGANIZATION_USAGE_QUERY);
  const [getOrgUsers] = useLazyQuery(GET_ORG_USERS_COUNT_QUERY);
  const [getAssignedToOthersRunsCount] = useLazyQuery(
    GET_ASSIGN_TO_OTHERS_RUNS_COUNT_QUERY
  );

  useEffect(() => {
    // Fetch the organization usage, if the organization is free plan
    if (selectedOrganization && selectedOrganization?.id) {
      // Fetch the organization usage
      fetchOrganizationUsage();
    }
  }, [selectedOrganization]);

  useEffect(() => {
    // Fetch the organization usage, if the organization is free plan
    if (
      selectedOrganization &&
      selectedOrganization?.id &&
      // selectedOrganization?.pricing_subscription?.pricing_plan
      //   ?.pricing_plan_type?.is_freeplan &&
      runChecklistTree?.checklist?.is_completed
    ) {
      // Fetch the organization usage
      const currentUsage = useOrganizationStore.getState().organizationGetStartedProgress;
      useOrganizationStore.getState().setOrganizationGetStartedProgress({
        ...currentUsage,
        runs: (currentUsage.runs || 0) + 1
      });
    }
  }, [runChecklistTree]);

  async function fetchOrganizationUsage() {
    const { data: orgUsers } = await getOrgUsers({
      variables: {
        orgId: selectedOrganization?.id
      },
      fetchPolicy: 'network-only'
    });

    const { data: organizationUsageData } = await getOrganizationUsage({
      variables: {
        orgId: selectedOrganization?.id
      },
      fetchPolicy: 'network-only'
    });

    const { data: assignedToOthersRunsCount } =
      await getAssignedToOthersRunsCount({
        variables: {
          orgId: selectedOrganization?.id
        },
        fetchPolicy: 'network-only'
      });
    
    if (organizationUsageData && orgUsers && assignedToOthersRunsCount) {
      setOrganizationGetStartedProgress({
        checklists: organizationUsageData?.checklist_created?.aggregate?.count,
        runs: organizationUsageData?.run_completed?.aggregate?.count,
        user: orgUsers?.org_invites_aggregate?.aggregate?.count + orgUsers?.org_users_aggregate?.aggregate?.count,
        assignedToOthersRunsCount:
          assignedToOthersRunsCount?.runs_aggregate?.aggregate?.count
      });
    }
  }

  return <></>;
}
