import { CREATE_RUN_AND_ASSIGN_MUTATION } from '@/graphql/runs.gql';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import {
  CLPrimaryButton,
  CLPrimaryDate,
  CLPrimaryInput,
  CLPrimarySelect,
  CLSecondaryButton
} from '../../../ui-controls';

import { v4 as uuidv4 } from 'uuid';
import { LoadingSpinWhiteCustom } from '../../../utils/loading.component';

import { GET_PROJECT_USERS_QUERY } from '@/graphql/projects.gql';
import { ProjectUser } from '@/types/projects.type';
import CLButton from '../../../ui-controls/default-ui-controls/button';
import { useRouter } from 'next/router';
import { useOrganizationStore } from '@/stores/organization.store';
import CLTooltip from '@/components/utils/tooltip';
import { shallow } from 'zustand/shallow';
import PlanAndBillingPopupComponent from '@/components/utils/plan-and-billing-popup.component';
import posthog from 'posthog-js';
import ProjectMembersPopupComponent from '@/components/projects/project-memebers-popup.component';
import { useProjectsStore } from '@/stores/projects.store';
import PERMISSIONS_CONSTANTS from '@/constants/permission-constants';

interface PopupProps {
  projectId: string;
  checklistId: string;
  checklistTitle: string;
  checklistVersionId: string;
  isOpen: boolean;
  isFromDesignPage?: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AssignChecklistPopupComponent({
  isOpen,
  setIsOpen,
  ...props
}: PopupProps) {
  // Variables
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const router = useRouter();

  // States
  const [projectUsers, setProjectUsers] = useState<
    { id: string; displayName: string }[]
  >([]);
  const [runName, setRunName] = useState<string>('');
  const [dueDate, setDueDate] = useState<string | null>(null);
  const [assignTo, setAssignTo] = useState<
    { id: string; displayName: string }[]
  >([]);

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [isPlanAndBillingPopupOpen, setIsPlanAndBillingPopupOpen] =
    useState<boolean>(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false)

  // Store
  const [selectedOrganization, organizationUsage] = useOrganizationStore(
    state => [state.selectedOrganization, state.organizationUsage],
    shallow
  );
  const [projectPermissions, newProjectIds] = useProjectsStore(
    s => [s.projectPermissions, s.newProjectIds],
    shallow
  );

  // GraphQL
  const { data, loading, error, refetch } = useQuery(GET_PROJECT_USERS_QUERY, {
    variables: { projectId: props.projectId }
  });
  const [createRunAndAssignMutation] = useMutation(
    CREATE_RUN_AND_ASSIGN_MUTATION
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!isInviteOpen) {
        try {
          await refetch(); // This refetch the query
        } catch (err) {
          console.error("Error fetching project users:", err);
        }
      }
    };
  
    fetchData();
    // Reset the values
    if (isOpen) {
      setErrorMsg('');
      setSuccessMsg('');
      setRunName(props.checklistTitle || '');
      setAssignTo([]);

      if (!dueDate) {
        setDueDate(tomorrowDate.toLocaleDateString('en-CA'));
      }
    }

    // Project users
    if (data && data?.project_users && data?.project_users?.length > 0) {
      const projectUsers = data?.project_users?.map(
        (projectInvite: ProjectUser) => {
          return {
            id: projectInvite?.user?.id,
            displayName:
              projectInvite?.user?.displayName || projectInvite?.user?.email
          };
        }
      );
      setProjectUsers(projectUsers);
    }
  }, [isOpen, data, isInviteOpen]);

  useEffect(() => {
    checkRunLimitation();
  }, [organizationUsage]);

  function validate(): string | void {
    if (!runName) {
      return 'Please enter name';
    }

    if (!assignTo || assignTo?.length == 0) {
      return 'Please select "Assign to"';
    }

    // if (!dueDate) {
    //   return 'Please select "Due date"';
    // }

    const selectedDate = new Date(dueDate as string);
    const today = new Date();
    const todayAtMidnight = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    if (selectedDate < todayAtMidnight) {
      return "Due date cannot be before today's date";
    }
  }

  async function startAssign() {
    // Reset
    setErrorMsg('');
    setSuccessMsg('');
    setIsLoading(true);

    // Validation
    const errorMessage = validate();

    if (errorMessage) {
      setErrorMsg(errorMessage);
      setIsLoading(false);

      return;
    }

    const runId = uuidv4();

    const runsAssignment = assignTo?.map(user => {
      return {
        run_id: runId,
        user_id: user?.id
      };
    });

    // Create a Run
    const { data: _runDetails, extensions: _projectExtentions } =
      await createRunAndAssignMutation({
        variables: {
          runs: [
            {
              id: runId,
              name: runName,
              due_at: dueDate,
              checklist_id: props?.checklistId,
              checklist_version_id: props?.checklistVersionId
            }
          ],
          runsAssignment: runsAssignment
        }
      });

    if (_runDetails?.['insert_runs']) {
      // Capture the event
      posthog.capture?.('Checklist Assigned', { 
        eventDate: new Date().toISOString().split('T')[0],
      });
      const currentUsage = useOrganizationStore.getState().organizationGetStartedProgress;
      useOrganizationStore.getState().setOrganizationGetStartedProgress({
        ...currentUsage,
        assignedToOthersRunsCount: (currentUsage.runs || 0) + 1
      });

      // if (
      //   selectedOrganization?.pricing_subscription?.pricing_plan
      //     ?.pricing_plan_type?.is_freeplan &&
      //   selectedOrganization?.pricing_subscription?.pricing_plan
      //     ?.pricing_limitations &&
      //   selectedOrganization?.pricing_subscription?.pricing_plan
      //     ?.pricing_limitations.length > 0
      // ) {
      //   const currentUsage = useOrganizationStore.getState().organizationUsage;
      //   useOrganizationStore.getState().setOrganizationUsage({
      //     ...currentUsage,
      //     runs: (currentUsage.runs || 0) + 1
      //   });
      // }
    }

    if (_runDetails?.['insert_runs']) {
      // Done
      setRunName('');
      setDueDate(tomorrowDate.toLocaleDateString('en-CA'));
      setAssignTo([]);
      setIsLoading(false);
      setSuccessMsg('Checklist is successfully assigned.');
    }
  }

  async function checkRunLimitation() {
    if (
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_plan_type?.is_freeplan &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations &&
      selectedOrganization?.pricing_subscription?.pricing_plan
        ?.pricing_limitations.length > 0
    ) {
      const runChecklistLimitation =
        selectedOrganization?.pricing_subscription?.pricing_plan?.pricing_limitations.find(
          limitation => limitation.type === 'runs'
        );

      if (
        runChecklistLimitation &&
        runChecklistLimitation?.value &&
        organizationUsage.runs &&
        organizationUsage.runs >= parseInt(runChecklistLimitation?.value)
      ) {
        return setShowUpgrade(true);
      } else {
        return setShowUpgrade(false);
      }
    }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-6 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`w-full ${showUpgrade && successMsg ? 'max-w-fit' : 'max-w-md'} transform rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all`}>
                  {/* Header */}
                  <div className="flex justify-between items-center">
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Assign checklist
                      </Dialog.Title>
                    </div>

                    <div>
                      <CLButton
                        className="p-2"
                        tabIndex={-1}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                            fill="black"
                            fillOpacity="0.5"
                          />
                        </svg>
                      </CLButton>
                    </div>
                  </div>

                  {/* Select assignee */}
                  <div className="mt-4">
                    <CLPrimarySelect
                      label="Assign to *"
                      isMulti={true}
                      value={assignTo}
                      isSearchable={true}
                      options={projectUsers}
                      getOptionLabel={option => option.displayName}
                      getOptionValue={option => option.id}
                      onChange={selectedUsers => {
                        if (Array.isArray(selectedUsers)) {
                          setAssignTo([...selectedUsers]);
                        }
                      }}
                      noOptionsMessage={() => 'No users found'}
                    />
                  </div>

                  {/* Invite a member */}
                  {props.isFromDesignPage &&
                    props.projectId &&
                    (newProjectIds[props.projectId] ||
                      projectPermissions?.[props.projectId]?.[
                        PERMISSIONS_CONSTANTS.MANAGE_PROJECT_MEMBERS
                      ]) && (
                      <div className="text-gray-600 text-sm flex items-center justify-end pt-2">
                        <span
                          className="underline underline-offset-2 cursor-pointer"
                          onClick={() => setIsInviteOpen(true)}
                        >
                          + Invite New Members
                        </span>
                      </div>
                    )}

                  {/* Run name */}
                  <div className="mt-4">
                    <CLPrimaryInput
                      type="text"
                      label="Run name *"
                      value={runName}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setRunName(event.target.value);
                      }}
                    />
                  </div>

                  {/* Due date */}
                  <div className="mt-4">
                    <CLPrimaryDate
                      type="date"
                      label="Due date"
                      value={dueDate ? dueDate.substring(0, 10) : ''}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (event.target.value) {
                          setDueDate(
                            new Date(event.target.value).toISOString()
                          );
                        } else {
                          setDueDate('');
                        }
                      }}
                    />
                  </div>

                  {/* Toolbar */}
                  <div className="mt-4 flex justify-between gap-2">
                    <div>
                      {/* Error message */}
                      {errorMsg && (
                        <div className="mt-2 text-red-400">{errorMsg}</div>
                      )}

                      {/* Success message */}
                      {successMsg && (
                        <div className="mt-2 whitespace-nowrap text-green-600">{successMsg}</div>
                      )}
                    </div>
                    <div className="flex gap-2">
                      <CLSecondaryButton
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        Cancel
                      </CLSecondaryButton>

                      {showUpgrade ? (
                        // <CLTooltip content="Upgrade To Assign">
                        <CLPrimaryButton
                          className='whitespace-nowrap'
                          onClick={() => {
                            setIsPlanAndBillingPopupOpen(true);
                          }}
                        >
                          Assign
                        </CLPrimaryButton>
                      ) : (
                        // </CLTooltip>
                        <CLPrimaryButton
                          disabled={isLoading}
                          onClick={() => {
                            startAssign();
                          }}
                        >
                          {isLoading && (
                            <LoadingSpinWhiteCustom className="w-4 h-4"></LoadingSpinWhiteCustom>
                          )}
                          Assign
                        </CLPrimaryButton>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <PlanAndBillingPopupComponent
        isOpen={isPlanAndBillingPopupOpen}
        setIsOpen={setIsPlanAndBillingPopupOpen}
      ></PlanAndBillingPopupComponent>

        <ProjectMembersPopupComponent
          projectId={props.projectId}
          isOpen={isInviteOpen}
          setIsOpen={setIsInviteOpen}
          isDefaultOpen={false}
        />
    </>
  );
}
