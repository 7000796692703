import { Dialog, Transition } from '@headlessui/react';
import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { CLPrimaryButton } from '../ui-controls';

interface Props {
  title?: string;
  //   description?: string;
  children: React.ReactNode;
  buttonText?: string;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export default function AlertPopupComponent({
  title,
  children,
  buttonText,
  isOpen,
  setOpen
}: Props) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => {
            setOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-4 text-left align-middle shadow-xl transition-all">
                  {/* Title */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title || 'Information'}
                  </Dialog.Title>

                  {/* Body */}
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">{children}</div>
                  </div>

                  {/* Footer */}
                  <div className="mt-4 flex justify-end gap-2">
                    <CLPrimaryButton
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      {buttonText || 'Ok'}
                    </CLPrimaryButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
