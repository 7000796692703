import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction } from 'react';

import { CLPrimaryCustomLabel } from '@/components/ui-controls';
import { useRunChecklistStore } from '@/stores/run/run-checklist.store';
import { RunChecklist } from '@/types/run.type';
import { shallow } from 'zustand/shallow';
import CLButton from '../../../ui-controls/default-ui-controls/button';
import RunCommentsComponent from '../../comments/run-comments.component';
import RunInstructionsComponent from '../../instructions/run-instructions.component';
import { RunChecklistTree } from '../../run-by-id.component';
import PublicRunCommentsComponent from '../../comments/public-run-comments.component';
import { useAnalyticsRowSelectionStore } from '@/stores/responses/responses-row-selection.store';

interface Props {
  node: RunChecklistTree;
  checklist: RunChecklist;
  onChangeOfStatusIndicator?: (
    statusIndicatorId: string,
    status: boolean,
    selectedRunNode: RunChecklistTree
  ) => void | undefined;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isSaving?: boolean;
}

export default function TaskDetailsPopup({
  node,
  checklist,
  onChangeOfStatusIndicator,
  isSaving,
  isOpen,
  setIsOpen
}: Props) {
  // Variables

  // Store
  const [statusIndicators, isStatusIndicatorDisabled, isPreview, isPublic, isSingleResponse] = useRunChecklistStore(
    s => [s.statusIndicators, s.isStatusIndicatorDisabled, s.isPreview, s.isPublic, s.isSingleResponse],
    shallow
  );

  const [isPublicRun, isAssignedRun] = useAnalyticsRowSelectionStore(
    s => [s.isPublicRun, s.isAssignedRun],
    shallow
  );

  return (
    <>
      <div>
        {/* Instructions */}
        <>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={() => {
                setIsOpen(false);
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-6 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-2xl h-[70vh] transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all flex flex-col">
                      {/* Header */}
                      <div className="p-4 flex items-center sticky top-0 z-1 bg-white">
                        <div>
                          {/* Title for Desktop screen */}
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900 line-clamp-1"
                            title={checklist?.title || 'Untitled'}
                          >
                            {/* Title */}
                            {checklist?.title || 'Untitled'}
                          </Dialog.Title>
                        </div>

                        <div className="flex items-center flex-grow justify-end">
                          {/* Close icon */}
                          <CLButton
                            className="p-2"
                            tabIndex={-1}
                            onClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L8 6.58579L14.2929 0.292893C14.6834 -0.0976311 15.3166 -0.0976311 15.7071 0.292893C16.0976 0.683417 16.0976 1.31658 15.7071 1.70711L9.41421 8L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L8 9.41421L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
                                fill="black"
                                fillOpacity="0.5"
                              />
                            </svg>
                          </CLButton>
                        </div>
                      </div>

                      {/* Body */}
                      <div className='p-4 overflow-y-auto'>
                        {/* Status indicators */}
                        {onChangeOfStatusIndicator && (
                          <>
                            {/* Status indicators for desktop screen */}
                            <div className="hidden lg:flex flex-wrap gap-4">
                              {statusIndicators?.map(
                                (statusIndicator, index) => (
                                  <div
                                    key={index}
                                    className="min-w-[80px] xl:min-w-[100px] flex justify-center"
                                  >
                                    <input
                                      type="checkbox"
                                      className="bg-white border-black text-[#474554] focus:ring-black/20 rounded w-6 h-6 cursor-pointer disabled:opacity-50 disabled:pointer-events-none"
                                      checked={
                                        (checklist?.is_completed &&
                                          (statusIndicator.id ===
                                            checklist.checklist_status_indicators_id ||
                                            !statusIndicator.id)) ||
                                        false
                                      }
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (onChangeOfStatusIndicator) {
                                          onChangeOfStatusIndicator(
                                            statusIndicator?.id || '',
                                            event.target.checked,
                                            node
                                          );
                                        }
                                      }}
                                      disabled={
                                        isSaving || isStatusIndicatorDisabled
                                      }
                                    />

                                    <div className="text-center ml-2 mr-4 pt-[2px]">
                                      {statusIndicator?.name}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>

                            {/* Status indicators for mobile screen */}
                            <div className="flex lg:hidden gap-4 flex-col ">
                              {statusIndicators?.map(
                                (statusIndicator, index) => (
                                  <div key={index} className="flex gap-2">
                                    <input
                                      type="checkbox"
                                      id={
                                        checklist?.id +
                                        '-' +
                                        statusIndicator?.id +
                                        '-status-indicator'
                                      }
                                      className="bg-white border-black text-[#474554] focus:ring-black/20 rounded w-6 h-6 cursor-pointer disabled:opacity-50 disabled:pointer-events-none"
                                      checked={
                                        (checklist?.is_completed &&
                                          (statusIndicator.id ===
                                            checklist.checklist_status_indicators_id ||
                                            !statusIndicator.id)) ||
                                        false
                                      }
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        if (onChangeOfStatusIndicator) {
                                          onChangeOfStatusIndicator(
                                            statusIndicator?.id || '',
                                            event.target.checked,
                                            node
                                          );
                                        }
                                      }}
                                      disabled={
                                        isSaving || isStatusIndicatorDisabled
                                      }
                                    />
                                    <CLPrimaryCustomLabel
                                      className="flex items-center cursor-pointer pt-[2px]"
                                      htmlFor={
                                        checklist?.id +
                                        '-' +
                                        statusIndicator?.id +
                                        '-status-indicator'
                                      }
                                    >
                                      {statusIndicator?.name}
                                    </CLPrimaryCustomLabel>
                                  </div>
                                )
                              )}
                            </div>

                            {/* Line */}
                            <hr className="h-px my-4  bg-gray-50 border-0 dark:bg-gray-300" />
                          </>
                        )}

                        {/* Description & Instructions */}
                        <div className="overflow-y-auto flex-grow">
                          <RunInstructionsComponent
                            selectedChecklistId={checklist?.id || ''}
                          />
                        </div>

                        {/* Run checklist comments */}
                        {checklist?.run_checklist_task_id && (
                          <div>
                            {isPublic || (isSingleResponse && isPublicRun) ? (
                              <PublicRunCommentsComponent
                                runChecklistId={
                                  checklist?.run_checklist_task_id
                                }
                              />
                            ) : (

                              ((isSingleResponse || !isPublic) && (
                                <RunCommentsComponent
                                  runChecklistId={
                                    checklist?.run_checklist_task_id
                                  }
                                />
                              ))
                            )}
                          </div>
                        )}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      </div>
    </>
  );
}
