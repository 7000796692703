import { gql } from "@apollo/client";

export const GET_PRO_PLAN_PRICING_QUERY = gql`
    query GetProPlanPricingQuery {
        pricing_plan_types(where: {name: {_eq: "Pro Plan"}}) {
            pricing_plans {
                id
                name
            }
        }
    }
`;

export const GET_DEFAULT_PRICING_QUERY = gql`
    query GetDefaultPricingQuery {
        pricing_versions(where: {is_default: {_eq: true}}) {
        id
        pricing_currencies {
            id
            name
            description
        }
        pricing_periods(where: {_or: {is_lifetime: {_is_null: true}}}, order_by: {order_number: asc}) {
            id
            name
            description
            order_number
            is_default_selection
        }
        pricing_plan_types(where: {_or: [{is_lifetime: {_eq: false}}, {is_lifetime: {_is_null: true}}]}, order_by: {order_number: asc}) {
            id
            name
            description
            order_number
            is_freeplan
            is_enterprise
            pricing_plan_type_features(order_by: {order_number: asc}) {
            id
            name
            description
            is_title
            order_number
            }
            pricing_plans {
            id
            name
            description
            pricing_plan_type_id
            pricing_currency_id
            pricing_period_id
            pricing_plan_features(order_by: {order_number: asc}) {
                id
                name
                description
                is_title
                order_number
            }
            pricing_price {
                id
                pricing_plan_id
                product_id
                amount
                amount_per_month
                actual_amount
                discount_amount
                discount_percentage
                discount_description
            }
            }
        }
        }
    }  
`;