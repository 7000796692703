import { gql } from "@apollo/client";

export const GetStartedContentQuery = gql`
    query GetStartedContent {
        get_started_content(order_by: { updated_at: asc }) {
            id
            title
            description
            updated_at
        }
    }
`;

export const GET_ORG_USERS_COUNT_QUERY = gql`
    query getOrgUsersCount($orgId: uuid!) {
        org_invites_aggregate(where: {org_id: {_eq: $orgId}}) {
            aggregate {
                count
            }
        }

        org_users_aggregate(where: {org_id: {_eq: $orgId}}) {
            aggregate {
                count
            }
        }
    }
`;


export const GET_ASSIGN_TO_OTHERS_RUNS_COUNT_QUERY = gql`
    query GetRunsCount($orgId: uuid!) {
        runs_aggregate(
            where: {
                checklist: {
                    project: { org_id: { _eq: $orgId }, is_deleted: { _is_null: true } }
                    is_deleted: { _is_null: true }
                }
                is_deleted: { _is_null: true }
                checklist_version_id: { _is_null: false }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;