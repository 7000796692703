export default function MetaInfo(props: any) {
  const { metaInfo } = props;

  // Card type
  const cardType =
    metaInfo['cardType'] && metaInfo['cardType'] == 'summary'
      ? metaInfo.cardType
      : 'summary_large_image';

  return (
    <>
      <meta name="description" content={metaInfo.description} />
      <meta name="keywords" content={metaInfo.keywords} />
      <meta name="robots" content="index,follow" />
      {/* Favicon Handling */}
      {metaInfo?.favicon ? (
        <link rel="icon" href={metaInfo.favicon} />
      ) : (
        <link rel="icon" href="/favicon.ico" />
      )}

      {/* Facebook Meta Tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaInfo.metaTitle} />
      <meta property="og:description" content={metaInfo.description} />
      <meta property="og:url" content={metaInfo.url} />
      <meta property="og:image" content={metaInfo.image} />
      <meta property="og:site_name" content={metaInfo.url} />

      {cardType == 'summary_large_image' && (
        <>
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
        </>
      )}

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content={cardType} />
      <meta name="twitter:title" content={metaInfo.metaTitle} />
      <meta name="twitter:description" content={metaInfo.description} />
      <meta name="twitter:image" content={metaInfo.image} />
      <meta name="twitter:site" content={metaInfo.url} />

      <meta name="theme-color" content="#ffffff" />
      <meta itemProp="description" content={metaInfo.description} />
      <meta itemProp="image" content={metaInfo.image} />
    </>
  );
}
