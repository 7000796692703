import DashboardMenuComponent from '@/components/dashboard-layout/dashboard-menu.component';

interface Props {
  children: React.ReactNode;
}

export default function DashboardLayout({ children }: Props) {
  return (
    <div className="h-screen flex">
      {/* Menu */}
      <div className="flex-[15rem_0_0] h-full overflow-hidden cl_secondary_background hidden md:block relative">
        <DashboardMenuComponent />
      </div>

      {/* Body */}
      <div className="flex-grow h-full overflow-y-auto">{children}</div>
    </div>
  );
}
